<template>
<div class="cart__coupon form-send-inline mb-3">
  <input
  type="text"
  class="form-control"
  placeholder="Bonus-Code eingeben"
  v-model.trim="promocode">
  <button class="btn-reset btn-send-input" @click="applyPromo"><i class="fas fa-gift"></i></button>
  <div class="badge bg-primary">{{ promocodeData }}</div>
</div>
</template>
<script>
import axios from 'axios'
import { useStore } from 'vuex'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
export default {
  setup () {
    const store = useStore()
    // ref
    const promocode = ref(null)
    const promocodeData = ref()
    onMounted(() => {
      store.dispatch('checkout/clearPromocode')
    })
    // actions
    async function applyPromo () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      await axios.post(apiUrl + 'promocode/apply', { re: apiKey, code: promocode.value }).then(resp => {
        if (resp.data.status) {
          const discount = resp.data.promo.discount
          const id = resp.data.promo.promo
          const code = resp.data.promo.code
          promocodeData.value = 'Ihr Preisvorteil ' + discount + '%'

          store.dispatch('checkout/setPromocode', { status: resp.data.status, discount: discount, id: id, code: code, total: 0 })
        } else {
          promocodeData.value = resp.data.msg
          store.dispatch('checkout/setPromocode', { status: false, discount: 0, id: '', code: '', total: 0 })
        }
      })
    }
    return {
      applyPromo,
      promocode,
      promocodeData
    }
  }
}
</script>
