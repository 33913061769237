<template>
<div class="hide-list">
  <span v-for="(item, index) in limitedLists" :key="index">
    <div v-if="index < limit" class="param-item row">
      <div class="param-item__name col-xl-4 col-5">{{ item.name }}:</div>
      <div class="param-item__value col-xl-8 col-7">{{ item.value }}</div>
    </div>
  </span>
  <button class="btn-reset btn-open-more" @click="moreAndLess(steps.length)">
    <i class="fas fa-angle-down me-1"></i>
    <span v-if="!showShortList" >Mehr</span>
    <span v-else>Minimieren</span>
  </button>
</div>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
export default {
  props: {
    steps: {
      require: true,
      type: Array
    }
  },
  setup (props) {
    const showShortList = ref(false)
    const limit = ref(5)
    const defaultLimit = ref(5)
    // computed
    const limitedLists = computed(() => {
      if (!showShortList.value) {
        return props.steps.slice(0, defaultLimit.value)
      }
      return props.steps
    })
    // actions
    function moreAndLess (filtersLength) {
      showShortList.value = !showShortList.value
      limit.value = filtersLength
      console.log(showShortList.value)
    }
    return {
      limit,
      showShortList,
      defaultLimit,
      limitedLists,
      moreAndLess
    }
  }
}
</script>
