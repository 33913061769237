<template>
  <span>
    <div class="form-check mb-3">
    <input class="form-check-input" type="radio" name="flexRadioDefault" id="pickup1" :checked="pickup" @change="setPickup">
    <label class="form-check-label" for="pickup1">Selbstabholung</label>
  </div>
  <div class="form-check mb-3">
    <input class="form-check-input" type="radio" id="pickup" :checked="!pickup" @change="unsetPickup">
    <label class="form-check-label" for="pickup">Lieferung an die angegebene Addresse</label>
  </div>
  <div class="row align-items-center mb-3">
    <div class="col-sm-9 col-8">
      <select @change="changeCountry" class="form-select">
        <option v-for="(c, index) in countries" :key="index" :selected="c.name == country.name">{{ c.name }}</option>
      </select>
    </div>
    <div class="col-sm-3 col-4">
      <div class="title-secondary bold-weight text-end">{{ $filters.currency(priceDelivery.delivery) }}</div>
    </div>
  </div>
  </span>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  setup () {
    const store = useStore()
    // computed
    const countries = computed(() => {
      return store.getters['checkout/countries']
    })
    const country = computed(() => {
      return store.getters['checkout/country']
    })
    const pickup = computed(() => {
      return store.getters['checkout/pickup']
    })
    const priceDelivery = computed(() => {
      return store.getters['checkout/price']
    })
    // actions
    function setPickup () {
      store.dispatch('checkout/updatePickup', true)
    }
    function unsetPickup () {
      store.dispatch('checkout/updatePickup', false)
    }
    function changeCountry (e) {
      store.dispatch('checkout/selectDelivery', e.target.value)
    }
    return {
      country,
      countries,
      pickup,
      priceDelivery,
      setPickup,
      unsetPickup,
      changeCountry
    }
  }
}
</script>
