<template>
  <div v-if="hasDiscount" class="ms-3 text-end">
    <div class="total-price total-price--discount">{{ $filters.currency(discounted) }}</div>
    <div class="total-price total-price--old mt-1">{{ $filters.currency(total) }}</div>
  </div>
  <div v-else class="total-price total-price mt-1">{{ $filters.currency(total) }}</div>
</template>
<script>
import { computed } from '@vue/reactivity'
export default {
  props: {
    discounted: Number,
    total: Number,
    percent: {
      default: 0
    }
  },
  setup (props) {
    const hasDiscount = computed(() => {
      return props.discounted && props.discounted < props.total
    })
    return {
      hasDiscount
    }
  }
}
</script>
