<template>
  <div v-if="hasDiscount" class="total-cost__item text-end">
    <span>{{ title }}</span>
    <div v-if="percent > 0" class="badge bg-danger">-{{ percent }}%</div>
    <div class="total-price total-price--discount">{{ $filters.currency(discounted) }}</div>
    <div class="total-price total-price--old mt-1">{{ $filters.currency(total) }}</div>
  </div>
  <div v-else class="total-cost__item text-end">
    <div>{{ title }}</div>
    <div class="total-price total-price mt-1">{{ $filters.currency(total) }}</div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
export default {
  props: {
    discounted: Number,
    total: Number,
    percent: {
      default: 0
    },
    title: String
  },
  setup (props) {
    const hasDiscount = computed(() => {
      return props.discounted && props.discounted < props.total
    })
    return {
      hasDiscount
    }
  }
}
</script>
